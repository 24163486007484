export const getFilenameFromContentDisposition = (header: string): string => {
  // CD response header can only be inline or attachment
  if (header && header.indexOf('attachment') !== -1) {
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(header);
    if (matches != null && matches[1]) return matches[1]?.replace(/['"]/g, '')?.trim() || '';
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wasRequestCanceled = (error: any): boolean => {
  return error.constructor.name === 'Cancel';
};
