export const ADD_BRAND_MODAL_TITLE = 'ADD BRAND';
export const SAVE_BRAND_MODAL_BUTTON = 'SAVE BRAND';
export const ADD_BRAND_MODAL_EN_BRAND_NAME_PLACEHOLDER = 'Brand Name';
export const ADD_BRAND_MODAL_AR_BRAND_NAME_PLACEHOLDER = 'Arabic Brand Name';
export const ADD_BRAND_MODAL_BUTTON_CANCEL_TEXT = 'Cancel';
export const ADD_BRAND_MODAL_SELECTOR_VARIANTS_TITLE = 'Verticals';
export const ADD_BRAND_MODAL_SUCCESS_MESSAGE = 'Brand successfully created!';
export const ADD_BRAND_MODAL_ERROR_MESSAGE = 'Error creating Brand!';

export const EDIT_BRAND_MODAL_TITLE = 'EDIT BRAND';
export const EDIT_BRAND_MODAL_EN_BRAND_NAME_PLACEHOLDER = 'Brand Name';
export const EDIT_BRAND_MODAL_AR_BRAND_NAME_PLACEHOLDER = 'Arabic Brand Name';
export const EDIT_BRAND_MODAL_BUTTON_CANCEL_TEXT = 'Cancel';
export const EDIT_BRAND_MODAL_SELECTOR_VARIANTS_TITLE = 'Verticals';
export const EDIT_BRAND_MODAL_SUCCESS_MESSAGE = 'Brand successfully edited!';
export const EDIT_BRAND_MODAL_ERROR_MESSAGE = 'Error editing Brand!';
export const EDIT_BRAND_MODAL_NOTIFICATION_MESSAGE =
  'Changes are only reflected on next updates';
export const BRAND_MODAL_THRESHOLD_LABEL = 'Apply 0 stock if';
export const BRAND_MODAL_STOCK_LABEL = 'Available Stock';
export const BRAND_REQUIRED_FIELD = 'This field is required';

export const PRODUCT_INVENTORY_TITLE = 'Catalog';
export const PRODUCT_INVENTORY_EXPORT_PRODUCTS_LABEL = 'Export Products';
export const PRODUCT_INVENTORY_EXPORT_INVENTORY_LABEL = 'Export Inventory';
export const PRODUCT_INVENTORY_DOWNLOAD_MODAL_TITLE = 'Download in Progress';
export const PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_1 =
  'Don’t close this window!';
export const PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_2 =
  'Hold on tight... We’re preparing your file for you.';
export const PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_3 =
  'All things come to those who wait.';
export const PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_PREPARING = 'Preparing...';
export const PRODUCT_INVENTORY_DOWNLOAD_MODAL_BODY_DOWNLOADED = 'Downloaded';
export const PRODUCT_INVENTORY_ERRORS_DOWNLOAD_TEXT = 'Download Errors';

export const PRODUCT_DETAILS_BACK_BUTTON_LABEL = 'Back to Catalog';
export const backToText = (text: string | undefined) =>
  `Back to ${text || ''} Catalog`;
export const PRODUCT_DETAILS_OVERVIEW_TITLE = 'Overview';
export const PRODUCT_DETAILS_OVERVIEW_VENDOR = 'Vendor';
export const PRODUCT_DETAILS_OVERVIEW_BRAND = 'Brand';
export const PRODUCT_DETAILS_OVERVIEW_SKU = 'SKU';
export const PRODUCT_DETAILS_OVERVIEW_CATEGORIES = 'Categories';
export const PRODUCT_DETAILS_OVERVIEW_BARCODE = 'Barcode';
export const PRODUCT_DETAILS_OVERVIEW_COLOURS = 'Colours';
export const PRODUCT_DETAILS_OVERVIEW_SIZES = 'Sizes';
export const PRODUCT_DETAILS_OVERVIEW_PRICES = 'Prices';
export const PRODUCT_DETAILS_EMPTY_TABLE_TITLE = 'No Variants added yet';
export const PRODUCT_DETAILS_VARIANTS_TITLE = 'Variants';
export const PRODUCT_DETAILS_SEARCH_PLACEHOLDER = 'Search Variant';

export const VARIANTS_TABLE_SECTION_PRICE = 'Price';
export const VARIANTS_TABLE_SECTION_SKU_VARIANT = 'SKU Variant';
export const VARIANTS_TABLE_SECTION_SALE_PRICE = 'Sale Price';
export const VARIANTS_TABLE_SECTION_AVAILABLE_STOCK = 'Available Stock';

export const PIMS_TITLE = 'PIMS File Review';
export const BASE_TABLE_PAGINATION_ROWS_PER_PAGE = 'Rows per page';
export const IMPORT_COMMON_CTA_FAILED = 'Failed to submit import file';

export const welcomeText = (text: string) => `Welcome, ${text}!`;
export const VENDOR_CATALOGS_HEADING_TEXT = 'All Vendors';
export const VENDOR_CATALOGS_SIDEMENU_INVENTORY_TITLE = 'Go to Inventory';
export const VENDOR_CATALOGS_SIDEMENU_INVENTORY_BUTTON_TEXT = ' Inventory';
export const VENDOR_CATALOGS_IMPORT_INVENTORY_BUTTON_TEXT = 'Import';
export const VENDOR_CATALOGS_IMPORT_HISTORY_EMPTY_TABLE_TITLE =
  'No imports added yet';
export const VENDOR_CATALOGS_IMPORT_HISTORY_EMPTY_TABLE_SUBTITLE =
  'Ready to Import?';
export const VENDOR_CATALOGS_IMPORT_HISTORY_TABLE_IN_PROGRESS = 'In Progress';
export const VENDOR_CATALOGS_ADD_BRAND_BUTTON_TEXT = '+ Add Brand';
export const VENDOR_CATALOGS_EDIT_BRAND_BUTTON_TEXT = 'Edit';

export const VENDOR_DETAILS_BUTTON_SHOW_BRANDS_TABLE = 'BRANDS';
export const VENDOR_DETAILS_MENU_BRANDS = 'BRANDS';
export const VENDOR_DETAILS_VENDOR_NAME_LABEL = 'Name';
export const VENDOR_DETAILS_EMPTY_TABLE_TITLE = 'No Brands added yet';
export const VENDOR_DETAILS_EMPTY_TABLE_SUBTITLE = "Let's add some Brands";
export const VENDOR_DETAILS_EMPTY_TABLE_ACTION_TITLE = 'ADD BRAND';

export const EMPTY_RESULTS_TITLE = 'No Results found';
export const EMPTY_RESULTS_SUBTITLE =
  "We couldn't find what you're looking for";

export const VENDOR_CATEGORIES_TITLE = 'All Categories';

export const VENDOR_SEARCH_PLACEHOLDER = 'Search Vendors';
export const CATALOG_SEARCH_PLACEHOLDER = 'Search SKU/Name';
export const CATEGORIES_SEARCH_PLACEHOLDER = 'Search Category';

export const timeoutTitle = (importType: string) =>
  `Your ${importType} import timed out`;
export const IMPORT_DETAILS_VIEW_TIMEOUT_SUBTITLE =
  'Please try again or contact support.';
export const IMPORT_DETAILS_VIEW_SCHEDULED_TITLE = 'Import Scheduled';
export const IMPORT_DETAILS_VIEW_SCHEDULED_TEXT = 'Your import was scheduled';
export const IMPORT_DETAILS_VIEW_SCHEDULED_DESCRIPTION =
  'We will need some time to process the file';
export const IMPORT_DETAILS_VIEW_FINISHED_TITLE = 'Import Finished';
export const IMPORT_DETAILS_VIEW_PROGRESS_TITLE = 'Import In Progress';
export const IMPORT_DETAILS_VIEW_PROGRESS_SUBTITLE =
  'We will need some time to process the file.';
export const LOGIN_TITLE = 'Welcome to Seller Lab';
export const LOGIN_SUBTITLE =
  'Enter your credentials to access your inventory.';
export const LOGIN_USERNAME = 'Username';
export const LOGIN_PASSWORD = 'Password';
export const LOGIN_ERROR_USERNAME = 'Username is required';
export const LOGIN_ERROR_MESSAGE = "Couldn't sign in, something went wrong";
export const IMPORT_TITLE = 'Import';

export const IMPORT_WIZARD_UPLOAD_HEADING = 'Let’s upload your file';
export const IMPORT_WIZARD_UPLOAD_TEXT_BOX_TITLE = 'Data template';
export const IMPORT_WIZARD_UPLOAD_TEXT_BOX_SUBTITLE_1 = 'Download a';
export const IMPORT_WIZARD_UPLOAD_TEXT_BOX_SUBTITLE_2 = 'sample file';
export const IMPORT_WIZARD_UPLOAD_TEXT_BOX_SUBTITLE_3 =
  ' to understand which is the expected format of import data.';

export const IMPORT_WIZARD_UPLOAD_ITEM1_TITLE = 'Check import fields';
export const IMPORT_WIZARD_UPLOAD_ITEM1_SUBTITLE =
  'Make sure your header field names match the Tamanna import format.';

export const IMPORT_WIZARD_UPLOAD_ITEM2_TITLE = 'Clean up your data';
export const IMPORT_WIZARD_UPLOAD_ITEM2_SUBTITLE =
  'Minimize the amout of errors before importing your data.';

export const IMPORT_WIZARD_UPLOAD_ITEM3_TITLE = 'Check file size';
export const IMPORT_WIZARD_UPLOAD_ITEM3_SUBTITLE = 'Import any CSV file up to';

export const IMPORT_WIZARD_UPLOAD_CONTAINER_TEXT = 'Drag your CSV file here or';
export const IMPORT_WIZARD_UPLOAD_CONTAINER_SUBTEXT = 'browse in your computer';

export const IMPORT_WIZARD_TYPE_TITLE = 'What type of import?';

export const PRODUCT_INVENTORY_BUTTOM = 'Import';

export const MAX_FILE_SIZE_BYTES = 16000000;
export const MAX_FILE_SIZE_MBYTES = MAX_FILE_SIZE_BYTES / 1000000;
