import React, { ReactElement } from 'react';
import { Flex, FlexProps } from 'rebass';
import styled from 'styled-components';
import BackButton from './BackButton';
import { isEmpty } from 'utils/primitives';
import { maxGridWidth } from 'styles/theme';

export const SECTION_SPACING = '40px';
export const BACK_BUTTON_SPACING = '40px';

const SideWrapper = styled(Flex)(({ theme: { space } }) => ({
  width: '100%',
  '@media(min-width: 1140px)': {
    width: maxGridWidth,
  },
  '@media(max-width: 1140px)': {
    paddingLeft: space[20],
    paddingRight: space[20],
  },
  flex: '1',
}));

const AboveWrapper = styled(Flex)(({ theme: { colors, space } }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  position: 'sticky',
  top: '65px',
  boxShadow: '0px 4px 5px rgba(172, 172, 172, 0.3)',
  zIndex: '1',
  backgroundColor: colors.neutral00,
  height: 'auto',
  paddingLeft: space[20],
  paddingRight: space[20],
}));

export interface LayoutProps extends FlexProps {
  above?: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
  below?: React.ReactNode;
  leftContainerProps?: FlexProps;
  rightContainerProps?: FlexProps;
  bottomContainerProps?: FlexProps;
  reverseMiddleOnMobile?: boolean;
  hasBackButton?: boolean;
}

const MainContentLayout = (props: LayoutProps): ReactElement => {
  const {
    above,
    left,
    right,
    below,
    leftContainerProps,
    rightContainerProps,
    bottomContainerProps,
    reverseMiddleOnMobile,
    hasBackButton,
    ...parentProps
  } = props;

  return (
    <Flex flexDirection="column" justifyContent="flex-start" {...parentProps}>
      {hasBackButton && <BackButton mt={BACK_BUTTON_SPACING} />}
      {Boolean(above) && <AboveWrapper>{above}</AboveWrapper>}
      {!!(left || right) && (
        <SideWrapper
          flexDirection={[
            reverseMiddleOnMobile ? 'column-reverse' : 'column',
            'row',
          ]}
          mt={SECTION_SPACING}
          mr="auto"
          ml="auto"
        >
          {!isEmpty(left) && (
            <Flex
              flexDirection="column"
              justifyContent="center"
              flex="1"
              pt={[reverseMiddleOnMobile ? SECTION_SPACING : 0, 0]}
              {...leftContainerProps}
            >
              {left}
            </Flex>
          )}
          {!isEmpty(right) && (
            <Flex
              flexDirection="column"
              width={['100%', '30%']}
              pl={[0, '64px']}
              pt={[SECTION_SPACING, 0]}
              {...rightContainerProps}
            >
              {right}
            </Flex>
          )}
        </SideWrapper>
      )}
      {Boolean(below) && (
        <Flex
          flex="1"
          flexDirection="column"
          mt={SECTION_SPACING}
          {...bottomContainerProps}
        >
          {below}
        </Flex>
      )}
    </Flex>
  );
};

export default MainContentLayout;
