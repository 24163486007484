import React from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Box, BoxProps } from 'rebass/styled-components';
import Button, { ButtonWithIconProps } from 'components-shared/ButtonWithIcon';
import { HomeRoute } from 'constants/routes';
import { isSSR } from 'utils/next';

interface BackButtonProps extends ButtonWithIconProps {
  withHistoryLabel?: string;
  noHistoryLabel?: string;
  noHistoryAction?: () => void;
  containerProps?: BoxProps;
}

const StyledButton = styled(Button)(
  ({ theme: { colors, fontSizes, fontWeights } }) => ({
    color: colors.neutral120,
    backgroundColor: 'transparent',
    outline: 'none',
    padding: 0,
    transition: '.2s opacity ease',
    ':hover': {
      opacity: 0.7,
      cursor: 'pointer',
    },
    fontSize: fontSizes[12],
    fontWeight: fontWeights.bold,
  })
);

/* if the user opens a page on a new tab, the length is already 2 and those correspond
to the new tab page of the browser and the current page */
const NO_HISTORY_LENGTH = 2;

const BackButton: React.FC<BackButtonProps> = ({
  withHistoryLabel,
  noHistoryLabel,
  noHistoryAction,
  containerProps,
  sx,
  ...buttonProps
}) => {
  const { pathname, push, back } = useRouter();

  if (pathname === HomeRoute || isSSR()) return null;
  return (
    <Box {...containerProps} sx={sx}>
      <StyledButton
        label={
          history.length > NO_HISTORY_LENGTH
            ? withHistoryLabel || 'Go Back'
            : noHistoryLabel || 'Go To Home Page'
        }
        iconProps={{ name: 'arrow', rotateDeg: 180, size: 12 }}
        onClick={() =>
          history.length > NO_HISTORY_LENGTH
            ? back()
            : noHistoryAction
            ? noHistoryAction
            : push(HomeRoute)
        }
        reverse
        {...buttonProps}
      />
    </Box>
  );
};

export default BackButton;
