/* eslint-disable no-console */
import { default as Logger } from 'loglevel';
import getConfig from 'next/config';
import { wasRequestCanceled } from './network';

const { publicRuntimeConfig } = getConfig();

enum Levels {
  TRACE,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  SILENT,
}

const initializeLogs = () => {
  const getLevel = (): Levels => {
    if (publicRuntimeConfig.logLevel) {
      const parsedLogLevel = +publicRuntimeConfig.logLevel;
      if (parsedLogLevel && Object.values(Levels).includes(parsedLogLevel)) return parsedLogLevel;
    }
    return Levels.TRACE;
  };

  const level = getLevel();
  Logger.setLevel(level);
};
initializeLogs();

type AnyJson = boolean | number | string | null | JsonArray | JsonMap;
type JsonMap = {
  [key: string]: AnyJson;
};
type JsonArray = Array<AnyJson>;

type LogArgsType = [string] | [string, AnyJson] | [AnyJson];

const LogTypes = ['trace', 'debug', 'info', 'log', 'warn', 'error', 'table'] as const;

type LogType = Record<typeof LogTypes[number], (...args: LogArgsType) => void>;

const LogGenerator = (): LogType => {
  const jsonSerializer = (data: unknown): string | null => {
    try {
      return JSON.stringify(data);
    } catch (error) {
      return null;
    }
  };
  const parseArgs = (args: LogArgsType): string | null => {
    const data: { message?: string; payload?: unknown; timestamp: number } = {
      timestamp: Date.now(),
    };
    if (typeof args[0] === 'string') {
      const [message, payload] = args;

      const processedData = jsonSerializer(payload || null);
      if (!payload || !processedData) return message;

      data.message = message;
      data.payload = payload;
    } else {
      const [payload] = args;

      const serializedData = jsonSerializer(payload);
      if (!serializedData) return null;

      data.payload = payload;
    }
    return jsonSerializer(data);
  };

  const logger: Partial<LogType> = {};

  LogTypes.forEach((type) => {
    logger[type] = (...args) => {
      const loggerMethod = Boolean(Logger?.[type]);
      const shouldUseConsole = (!loggerMethod && console?.[type]) || !loggerMethod;

      if (shouldUseConsole) return console[type](...args);

      const payload = parseArgs(args);
      if (!payload) return;

      if (loggerMethod) Logger[type](payload);
    };
  });

  return logger as LogType;
};

export const Log = LogGenerator();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logGeneralError(description: string, error: any) {
  // ignore errors regarding request cancelation,
  // because they're not really errors and should be justified to the user
  if (wasRequestCanceled(error)) return;
  Log.error(description, error.response?.data || error);
}
