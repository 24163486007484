import axios, { CancelTokenSource } from 'axios';
import { useEffect, useRef } from 'react';

const MAX_PENDING_TOKENS = 10;

const useCancelToken = () => {
  const cancelTokens = useRef<CancelTokenSource[]>([]);

  const requestCancelToken = (cancelPrevious = false) => {
    if (cancelPrevious) cancelAllTokens();
    const token = axios.CancelToken.source();
    if (cancelTokens.current.length === MAX_PENDING_TOKENS) {
      cancelTokens.current.shift();
    }
    cancelTokens.current.push(token);
    return token;
  };

  const cancelAllTokens = (reason = 'Request was manually canceled') => {
    if (cancelTokens.current.length === 0) return;
    cancelTokens.current.forEach((token) => token.cancel(reason));
  };

  useEffect(() => {
    return () => cancelAllTokens('Component was unmounted');
  }, []);

  return {
    getCancelToken: requestCancelToken,
    cancelAllTokens,
    tokens: cancelTokens.current,
  };
};

export default useCancelToken;
