import { logGeneralError } from './logs';
import { wasRequestCanceled } from './network';
import { ApiError, ApiResponse, PaginationFields } from 'models';
import { FetcherResponse } from 'api/fetcher';
import { Nullable } from 'models/utils';

export const parseApiResponse = <T>({
  data,
  statusCode,
}: Pick<
  FetcherResponse<T | ApiError>,
  'data' | 'statusCode'
>): ApiResponse<T> => {
  const hasError = (data as ApiError).errorCode !== undefined;
  return {
    hasError,
    statusCode,
    data: !hasError ? (data as T) : null,
    error: hasError ? (data as ApiError) : null,
    wasCanceled: false,
  };
};

/**
 * Handles general API errors, like a 500 or a canceled request
 * by returning the appropriate ApiResponse.
 * These errors can be validated using the hasError and wasCanceled flags.
 */
export const apiErrorBoundary = async <T>(
  handler: () => Promise<ApiResponse<T>>,
  generalErrorMessage: string
): Promise<ApiResponse<T>> => {
  try {
    return await handler();
  } catch (error) {
    logGeneralError(generalErrorMessage, error);
    return {
      hasError: true,
      wasCanceled: wasRequestCanceled(error),
      data: null,
      error: null,
      statusCode: 500,
    };
  }
};

export const getPaginationQuery = (
  pagination: Nullable<PaginationFields>
): string => {
  let query = `page=${pagination?.page ?? 0}`;
  if (pagination?.sort) query = `${query}&sort=${pagination.sort}`;
  if (pagination?.size) query = `${query}&size=${pagination.size}`;
  if (pagination?.filter) query = `${query}&filter=${pagination.filter}`;
  if (pagination?.name) query = `${query}&name=${pagination.name}`;
  if (pagination?.searchTerm)
    query = `${query}&searchTerm=${pagination.searchTerm}`;
  return query;
};
